import * as React from 'react';
import { SVGType } from 'Shared/utils/svgType';
const LinkSVG = ({ className }: SVGType) => (
  <svg className={`svg ${className}`} width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.37879 11.1215L11.6214 6.87887M5.78809 8.46978L4.72743 9.53044C3.55585 10.702 3.55563 12.6015 4.7272 13.7731C5.89877 14.9446 7.79849 14.9446 8.97007 13.7731L10.0303 12.7124M8.96961 5.2877L10.0303 4.22704C11.2018 3.05547 13.1012 3.05552 14.2728 4.22709C15.4444 5.39867 15.4447 7.29811 14.2731 8.46968L13.2125 9.53035"
      stroke="#F2F2F2"
      strokeWidth="1.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default LinkSVG;
