import { Connector } from 'Modules/WebsiteModule';
import { Website } from '../EnumTypes/ActionEnumTypes';
import { Actions } from 'Modules/ViewManagementModule';

// const init = () => ({
//   type: Website.GET_DASHBOARD_WEBSITES_AMOUNTS_INIT,
// });

const success = (data) => ({
  type: Website.GET_DASHBOARD_PARTNER_AMOUNTS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Website.GET_DASHBOARD_PARTNER_AMOUNTS_FAILURE,
});

const getDashboardPartnerAmounts = () => async (dispatch) => {
  // dispatch(init());
  try {
    dispatch(Actions.showLoader());
    const { data } = await Connector.getDashboardPartnerAmounts();
    dispatch(success(data));
    dispatch(Actions.hideLoader());
  } catch (err) {
    dispatch(Actions.hideLoader());
    dispatch(failure());
  }
};

export default getDashboardPartnerAmounts;
