import * as Types from 'Shared/models/AuthModel';
import * as ActionTypes from '../Actions/EnumTypes/ActionEnumTypes';
import { AuthAction } from '../Actions/Models/AuthActionModel';

export type State = Types.AuthStore;

export const initialState: State = {
  token: null,
  authorizedUser: 0,
};

export function authReducer(state: State = initialState, action: AuthAction) {
  switch (action.type) {
    case ActionTypes.Auth.LOG_IN_SUCCESS: {
      localStorage.setItem('isUserLogged', JSON.stringify(true));
      return {
        ...state,
        token: action.payload.authData.token,
      };
    }
    case ActionTypes.Auth.LOG_OUT_SUCCESS: {
      localStorage.removeItem('isUserLogged');
      localStorage.removeItem('loggedUserCurrency');
      return {
        ...state,
        token: null,
        tokenExpires: null,
        authorizedUser: 0,
      };
    }
    case ActionTypes.Auth.LOG_OUT_FAILURE: {
      localStorage.removeItem('isUserLogged');
      return {
        ...state,
        token: null,
        tokenExpires: null,
        authorizedUser: null,
      };
    }
    case ActionTypes.Auth.GET_AUTHORIZED_USER_FAILURE: {
      return {
        ...state,
        authorizedUser: null,
      };
    }
    case ActionTypes.Auth.GET_AUTHORIZED_USER_SUCCESS: {
      return {
        ...state,
        authorizedUser: action.payload.data,
      };
    }
    case ActionTypes.Auth.SET_REFRESH_TOKEN: {
      return {
        ...state,
        token: action.payload.authData.token,
      };
    }
    case ActionTypes.Auth.GET_REFRESH_TOKEN_SUCCESS: {
      localStorage.setItem('isUserLogged', JSON.stringify(true));
      return {
        ...state,
        token: action.payload.token,
      };
    }
    default: {
      return state;
    }
  }
}
