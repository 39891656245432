import * as React from 'react';
import ButtonCorgi from 'Assets/Image/buttonCorgi.svg';
import SVG from 'Shared/components/SVG';
import { SVG_TYPE } from 'Shared/enums';
import { FormattedMessage } from 'react-intl';


const AboutCorgi = () => {
  return (
    <div className="page-corgi">
      <div className="container">
        <h1>
          <FormattedMessage id="global.about_corgi.heading_site" />
        </h1>
        <div className="corgi_advance">
          <div>
            <p>
              <FormattedMessage id="global.about_corgi.descript" />
            </p>
            <span>
              <FormattedMessage id="global.about_corgi.descript_2" />
            </span>
          </div>
          <img src={ButtonCorgi} alt="" />
        </div>

        <div className="corgi_complex">
          <h2>
            <FormattedMessage id="global.about_corgi.complex" />
          </h2>
          <div className="corgi_complex-list">
            <ul>
              <li>
                <FormattedMessage id="global.about_corgi.list_1_1" />
              </li>
              <li>
                <FormattedMessage id="global.about_corgi.list_1_2" />
              </li>
            </ul>
            <ul>
              <li>
                <FormattedMessage id="global.about_corgi.list_2_1" />
              </li>
              <li>
                <FormattedMessage id="global.about_corgi.list_2_2" />
              </li>
            </ul>
            <ul>
              <li>
                <FormattedMessage id="global.about_corgi.list_3_1" />
              </li>
              <li>
                <FormattedMessage id="global.about_corgi.list_3_2" />
              </li>
            </ul>
            <ul>
              <li>
                <FormattedMessage id="global.about_corgi.list_4_1" />
              </li>
              <li>
                <FormattedMessage id="global.about_corgi.list_4_2" />
              </li>
            </ul>
          </div>
        </div>
        <div className="corgi_hacker">
          <SVG className="svg svg-" type={SVG_TYPE.CORGI_CAPE} />
          <div className="corgi_hacker-text">
            <p>
              <FormattedMessage id="global.about_corgi.hacker_heading" />
            </p>
            <span>
              <FormattedMessage id="global.about_corgi.hacker_desc_1" />
            </span>
            <span>
              <FormattedMessage id="global.about_corgi.hacker_desc_2" />
            </span>
            <span>
              <FormattedMessage id="global.about_corgi.hacker_desc_3" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutCorgi;
