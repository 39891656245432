enum SVG_TYPE {
  LOGO = 'LOGO',
  LOGO_WHITE = 'LOGO',
  ACCEPT_GREEN = 'ACCEPT_GREEN',
  ARROW_DOWN = 'ARROW_DOWN',
  CHEVRON_RIGHT = 'CHEVRON_RIGHT',
  CHEVRON_LEFT = 'CHEVRON_LEFT',
  CHEVRON_RIGHT_LIGHT = 'CHEVRON_RIGHT_LIGHT',
  BACKUP = 'BACKUP',
  BROKEN_CONNECTED = 'BROKEN_CONNECTED',
  CARD = 'CARD',
  EXCLAMATION = 'EXCLAMATION',
  FILE = 'FILE',
  GROUP_FILE = 'GROUP_FILE',
  CIRCLE = 'CIRCLE',
  PADLOCK = 'PADLOCK',
  POLYGON = 'POLYGON',
  QUESTION = 'QUESTION',
  TIME_BACKUP = 'TIME_BACKUP',
  UPLOAD_BACKUP = 'UPLOAD_BACKUP',
  CLOSE = 'CLOSE',
  CLOSE_POPUP = 'CLOSE_POPUP',
  PLUS = 'PLUS',
  ACCEPT_ORANGE = 'ACCEPT_ORANGE',
  SETTINGS = 'SETTINGS',
  MAIN_LOGO = 'MAIN_LOGO',
  CHEVRON_DOWN = 'CHEVRON_DOWN',
  CORGI_WOOF = 'CORGI_WOOF',
  PREMIUM_WOOF = 'PREMIUM_WOOF',
  CORGI_WOOF_TAK = 'CORGI_WOOF_TAK',
  CORGI_BANDA = 'CORGI_BANDA',
  CORGI_PREMIUM = 'CORGI_PREMIUM',
  SIT_DOWN = 'SIT_DOWN',
  PACKETS_BASIC = 'PACKETS_BASIC',
  PACKETS_PREMIUM = 'PACKETS_PREMIUM',
  PACKETS_PRO = 'PACKETS_PRO',
  CORGI_CAPE = 'CORGI_CAPE',
  PAWS = 'PAWS',
  QUEST = 'QUEST',
  CALL = 'CALL',
  DOGPAWS = 'DOGPAWS',
  BUST_WOOF = 'BUST_WOOF',
  TRIANGLE_DOGS = 'TRIANGLE_DOGS',
  WOOF_PREMIUM = 'WOOF_PREMIUM',
  YOU_KNOW = 'YOU_KNOW',
  EXCLAMATION_CIRCLE = 'EXCLAMATION_CIRCLE',
  INFORMATION = 'INFORMATION',
  EYE = 'EYE',
  CROSSED_EYE = 'CROSSED_EYE',
  VISA = 'VISA',
  MASTERCARD = 'MASTERCARD',
  HAMBURGER = 'HAMBURGER',
  DASHBOARD = 'DASHBOARD',
  PAGES = 'PAGES',
  PAYMENTS = 'PAYMENTS',
  PARTNERS = 'PARTNERS',
  RIGHT_ARROW = 'RIGHT_ARROW',
  ARROW_UP = 'ARROW_UP',
  RIGHT_ARROW_DASH = 'RIGHT_ARROW_DASH',
  FEATHER_DATABASE = 'FEATHER_DATABASE',
  FEATHER_LOCK = 'FEATHER_LOCK',
  FEATHER_SEETINGS = 'FEATHER_SEETINGS',
  FEATHER_GOOGLE = 'FEATHER_GOOGLE',
  HELP = 'HELP',
  SMS = 'SMS',
  EMAIL = 'EMAIL',
  WP_WARNING = 'WP_WARNING',
  RIGHT_ARROW_WHITE = 'RIGHT_ARROW_WHITE',
  CORGI_WITHOUT_WOOF = 'CORGI_WITHOUT_WOOF',
  REPORT = 'REPORT',
  ARROW_RIGHT_DARK = 'ARROW_RIGHT_DARK',
  DOWNLOAD = 'DOWNLOAD',
  OKAY = 'OKAY',
  CORGI_BABY = 'CORGI_BABY',
  PHP_VER = 'PHP_VER',
  LOCK = 'LOCK',
  ARROW_RIGHT_LIGHT = 'ARROW_RIGHT_LIGHT',
  RIGHT_ARROW_BOLD = 'RIGHT_ARROW_BOLD',
  CORGI_PREMIUM_REPORT = 'CORGI_PREMIUM_REPORT',
  MATERIAL_SMS = 'MATERIAL_SMS',
  FEATHER_MAIL = 'FEATHER_MAIL',
  FILE_UPLOAD = 'FILE_UPLOAD',
  DROP_DOWN = 'DROP_DOWN',
  EXIT = 'EXIT',
  PASSED = 'PASSED',
  FAILED = 'FAILED',
  ARROW_RIGHT_LIGHT_DIAG = 'ARROW_RIGHT_LIGHT_DIAG',
  CHECKED = 'CHECKED',
  DOMAIN_VALID = 'DOMAIN_VALID',
  DOMAIN_NOT_VALID = 'DOMAIN_NOT_VALID',
  DOMAIN_CHECK_IN_PROGRSS = 'DOMAIN_CHECK_IN_PROGRSS',
  GLOBE = 'GLOBE',
  CORGI_FULL_BABY = 'CORGI_FULL_BABY',
  CORGI_PREMIUM_FLY = 'CORGI_PREMIUM_FLY',
  CHECKER_BACKGROUND = 'CHECKER_BACKGROUND',
  UPDATE = 'UPDATE',
  MONITOR = 'MONITOR',
  SECURITY = 'SECURITY',
  BACKUP_ICON = 'BACKUP_ICON',
  PAWS_2 = 'PAWS_2',
  CORGI_PREMIUM_CIRCLE = 'CORGI_PREMIUM_CIRCLE',
  EDIT = 'EDIT',
  RADIO_CHECKED = 'RADIO_CHECKED',
  HERO_SECTION_BG = 'HERO_SECTION_BG',
  HERO_SECTION_SHIELD = 'HERO_SECTION_SHIELD',
  FILE_JPG = 'FILE_JPG',
  FILE_SVG = 'FILE_SVG',
  FILE_PNG = 'FILE_PNG',
  FILE_JPEG = 'FILE_JPEG',
  TWISTED_ARROW = 'TWISTED_ARROW',
  CHECK = 'CHECK',
  PACKETS_BASIC_NO_BG = 'PACKETS_BASIC_NO_BG',
  PACKETS_PREMIUM_NO_BG = 'PACKETS_PREMIUM_NO_BG',
  PACKETS_PRO_NO_BG = 'PACKETS_PRO_NO_BG',
  SHIELD_BULE_CHECK = 'SHIELD_BULE_CHECK',
  CLOSE_CIRCLE_FILL = 'CLOSE_CIRCLE_FILL',
  CORGI_LOST = 'CORGI_LOST',
  CLOSE_CIRCLE = 'CLOSE_CIRCLE',
  CLOSE_MENU = 'CLOSE_MENU',
  CLIENTS = 'CLIENTS',
  CHECKER = 'CHECKER',
  ARROW_LEFT = 'ARROW_LEFT',
  CREDIT_CARD = 'CREDIT_CARD',
  CREDIT_CARD2 = 'CREDIT_CARD2',
  PERSON = 'PERSON',
  BUILDING = 'BUILDING',
  REPORT_ICON = 'REPORT_ICON',
  COMMUNICATION = 'COMMUNICATION',
  LOCK_ICON = 'LOCK_ICON',
  WARNING = 'WARNING',
  SETTINGS_ICON = 'SETTINGS_ICON',
  GIFT = 'GIFT',
  PENCIL = 'PENCIL',
  BIN = 'BIN',
  BLUE_CIRCLE_DESKTOP = 'BLUE_CIRCLE_DESKTOP',
  BLUE_CIRCLE_HIGH = 'BLUE_CIRCLE_HIGH',
  CORGI_PRO_ROTATED = 'CORGI_PRO_ROTATED',
  CORGI_FLY_GLASSES = 'CORGI_FLY_GLASSES',
  ADD_PLUS = 'ADD_PLUS',
  PHP_ICON = 'PHP_ICON',
  WORDPRESS = 'WORDPRESS',
  PLUGIN = 'PLUGIN',
  THEME = 'THEME',
  STOP_SIGN = 'STOP_SIGN',
  MAIL_ICON = 'MAIL_ICON',
  SHUFFLE = 'SHUFFLE',
  WARNING_ICON = 'WARNING_ICON',
  FTP_ICON = 'FTP_ICON',
  LOCK_ICON_2 = 'LOCK_ICON_2',
  RED_CIRCLE = 'RED_CIRCLE',
  CALENDAR = 'CALENDAR',
  ARROW_RIGHT = 'ARROW_RIGHT',
  LOGOUT = 'LOGOUT',
  FILE_DOWNLOAD = 'FILE_DOWNLOAD',
  CORGI_SHIELD = 'CORGI_SHIELD',
  MONITOR_ICON = 'MONITOR_ICON',
  UPDATE_ICON = 'UPDATE_ICON',
  SHIELD_ICON = 'SHIELD_ICON',
  MAIL_ICON_2 = 'MAIL_ICON_2',
  UPLOAD_ICON = 'UPLOAD_ICON',
  FILE_ICON = 'FILE_ICON',
  PAW_1 = 'PAW_1',
  PAW_2 = 'PAW_2',
  PAW_3 = 'PAW_3',
  MONEY = 'MONEY',
  DESKTOP_ICON = 'DESKTOP_ICON',
  SEARCH_ICON = 'SEARCH_ICON',
  SMILE_ICON = 'SMILE_ICON',
  ANNOUNCEMENT_ICON = 'ANNOUNCEMENT_ICON',
  MAGNET_LOGO = 'MAGNET_LOGO',
  REVIEW_BACKGROUND = 'REVIEW_BACKGROUND',
  APOSTROPHE = 'APOSTROPHE',
  CIRCLE_CHECK = 'CIRCLE_CHECK',
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  LINKEDIN = 'LINKEDIN',
  YOUTUBE = 'YOUTUBE',
  PL_DO_YOU_KNOW = 'PL_DO_YOU_KNOW',
  EN_DO_YOU_KNOW = 'EN_DO_YOU_KNOW',
  DE_DO_YOU_KNOW = 'DE_DO_YOU_KNOW',
  CORGI_HEADPHONES = 'CORGI_HEADPHONES',
  QUESTION_MARK = 'QUESTION_MARK',
  BACKUP_CLOUD = 'BACKUP_CLOUD',
  COPYRIGHT_SECOURITY = 'COPYRIGHT_SECOURITY',
  AUTOMATIC_UPDATE = 'AUTOMATIC_UPDATE',
  CONSTANT_MONITORING = 'CONSTANT_MONITORING',
  BIG_LOUPE = 'BIG_LOUPE',
  BULB = 'BULB',
  HOSTING = 'HOSTING',
  PHONE_ICON = 'PHONE_ICON',
  LOCATION_ICON = 'LOCATION_ICON',
  MAIL_ICON_3 = 'MAIL_ICON_3',
  CORGI_GROUP = 'CORGI_GROUP',
  MONEY_2 = 'MONEY_2',
  CORGI_SUIT = 'CORGI_SUIT',
  BUBBLE = 'BUBBLE',
  QUESTION_MARKS = 'QUESTION_MARKS',
  WEBSITE_KREACJE_LOGO = 'WEBSITE_KREACJE_LOGO',
  RED_DOG_LOGO = 'RED_DOG',
  NATALIA_JAGUS_LOGO = 'NATALIA_JAGUS_LOGO',
  RED_WOLF_LOGO = 'RED_WOLF_LOGO',
  LINK = 'LINK',
  PROFITTO_LOGO = 'PROFITTO_LOGO',
  SO_BRIGHT_LOGO = 'SO_BRIGHT_LOGO',
  SINGLE_PAGE_BACKUPS = 'SINGLE_PAGE_BACKUPS',
  SINGLE_PAGE_BACKUP = 'SINGLE_PAGE_BACKUP',
  SINGLE_PAGE_MONITORING = 'SINGLE_PAGE_MONITORING',
  SINGLE_PAGE_BACKUPS_EN = 'SINGLE_PAGE_BACKUPS_EN',
  SINGLE_PAGE_BACKUP_EN = 'SINGLE_PAGE_BACKUP_EN',
  SINGLE_PAGE_MONITORING_EN = 'SINGLE_PAGE_MONITORING_EN',

  //flags
  FLAG_AU = 'FLAG_AU',
  FLAG_AT = 'FLAG_AT',
  FLAG_BE = 'FLAG_BE',
  FLAG_BG = 'FLAG_BG',
  FLAG_CA = 'FLAG_CA',
  FLAG_CL = 'FLAG_CL',
  FLAG_HR = 'FLAG_HR',
  FLAG_CY = 'FLAG_CY',
  FLAG_CZ = 'FLAG_CZ',
  FLAG_DK = 'FLAG_DK',
  FLAG_EE = 'FLAG_EE',
  FLAG_FI = 'FLAG_FI',
  FLAG_FRAB = 'FLAG_FRAB',
  FLAG_DE = 'FLAG_DE',
  FLAG_EL = 'FLAG_EL',
  FLAG_HU = 'FLAG_HU',
  FLAG_IS = 'FLAG_IS',
  FLAG_IO = 'FLAG_IO',
  FLAG_IE = 'FLAG_IE',
  FLAG_IT = 'FLAG_IT',
  FLAG_JP = 'FLAG_JP',
  FLAG_LV = 'FLAG_LV',
  FLAG_LT = 'FLAG_LT',
  FLAG_LU = 'FLAG_LU',
  FLAG_MT = 'FLAG_MT',
  FLAG_MX = 'FLAG_MX',
  FLAG_NL = 'FLAG_NL',
  FLAG_NZ = 'FLAG_NZ',
  FLAG_NO = 'FLAG_NO',
  FLAG_PL = 'FLAG_PL',
  FLAG_PT = 'FLAG_PT',
  FLAG_RO = 'FLAG_RO',
  FLAG_RU = 'FLAG_RU',
  FLAG_SA = 'FLAG_SA',
  FLAG_SK = 'FLAG_SK',
  FLAG_SL = 'FLAG_SL',
  FLAG_PZ = 'FLAG_PZ',
  FLAG_KR = 'FLAG_KR',
  FLAG_ES = 'FLAG_ES',
  FLAG_SE = 'FLAG_SE',
  FLAG_CH = 'FLAG_CH',
  FLAG_TW = 'FLAG_TW',
  FLAG_TH = 'FLAG_TH',
  FLAG_TR = 'FLAG_TR',
  FLAG_UA = 'FLAG_UA',
  FLAG_AE = 'FLAG_AE',
  FLAG_IRL = 'FLAG_IRL',
  FLAG_XI = 'FLAG_XI',

  FLAG_USA = 'FLAG_USA',
}

export default SVG_TYPE;
