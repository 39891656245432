/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react';
import premium from 'Assets/Image/package_premium.svg';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FormattedMessage } from 'react-intl';
import { Button, SVG } from 'Shared/components';
import { SVG_TYPE, VALIDATION_FIELD } from 'Shared/enums';
import ErrorsBox from 'Shared/components/errorsBox';
import * as Yup from 'yup';
import RegularField from 'Shared/form/regularField/regularField';
import ChevronWhite from 'Assets/Image/Icon/icon_chevron_white.svg';
import { getLocalStorage, stripePricing } from 'Shared/utils';

type P = {
  checkerRegistration?: any;
  formatMessage: any;
  url?: string;
  setIsUserRegister: any;
  setEmailRegister: any;
  closeRegisterModal: any;
  translation: any;
};

const RegisterModal = ({ formatMessage, checkerRegistration, url, setIsUserRegister, closeRegisterModal, setEmailRegister }: P) => {
  const [expand, setExpand] = useState(false);
  const toggleExpand = () => setExpand(!expand);
  const [apiError, setApiError] = React.useState(false);

  const Validation = () => {
    return Yup.object().shape({
      email: Yup.string()
        .email(formatMessage({ id: 'global.validation.wrong_email' }))
        .required(formatMessage({ id: 'global.validation.required' }))
        .max(254, VALIDATION_FIELD(formatMessage).MAX_254),

      password: Yup.string()
        .required(formatMessage({ id: 'global.validation.required' }))
        .min(8, formatMessage({ id: 'global.validation.min_8' }))
        .max(254, VALIDATION_FIELD(formatMessage).MAX_254),
      checkbox_privacy: Yup.boolean()
        .required(formatMessage({ id: 'global.validation.required' }))
        .oneOf([true], formatMessage({ id: 'global.validation.card_rules' })),
    });
  };
  const ref = getLocalStorage('refCode');
  // const ref = getLocalStorage('refCode');
  const translation = localStorage.getItem('translation');

  return (
    <div className="checker-register__content">
      <img className="image" src={premium} alt="icon premium" />
      <div className="heading">{formatMessage({ id: 'global.details_partner' })}</div>
      <p className="paragraph">
        {' '}
        <FormattedMessage id="global.preorder.rulez_0" />{' '}
      </p>
      {apiError ? <ErrorsBox text={formatMessage({ id: 'errors.form.incorrect_data' })} /> : null}
      <Formik
        enableReinitialize
        initialValues={{ email: '', password: '', checkbox_privacy: '', url, currency: stripePricing().currencies, ref: ref ? ref : null }}
        validationSchema={Validation()}
        onSubmit={async (values) => {
          const response = await checkerRegistration(values);
          if (response.status === 422) {
            setApiError(true);
          } else {
            setApiError(false);
            setIsUserRegister(true);
            closeRegisterModal();
            setEmailRegister(values.email);
            setTimeout(() => {
              window.scrollTo(0, 0);
            }, 100);
          }
        }}>
        {({ errors, handleSubmit }) => (
          <Form>
            {
              <>
                <div className="second_step-wrapper second_step-wrapper--partner">
                  <div className="popUp--form-l ">
                    <Field
                      type="text"
                      label={<FormattedMessage id="global.packet_form.email" />}
                      name="email"
                      component={RegularField}
                      errors={errors}
                    />
                  </div>
                  <div className="popUp--form-r popUp--form-r--padding">
                    <Field
                      label={<FormattedMessage id="global.home_page.packet_card_password" />}
                      name="password"
                      type="password"
                      component={RegularField}
                      errors={errors}
                    />
                  </div>
                  <div className="second_step-form-rulez">
                    <div className="rulez">
                      {/* <span className="second_step-form-rulez-text">
                          <FormattedMessage id="global.preorder.rulez_0" />
                        </span> */}
                    </div>
                    <div className="checkbox__rule checkbox__rule--preorder">
                      <label className="container">
                        <Field name="checkbox_privacy" type="checkbox" />
                        <ErrorMessage name="checkbox_privacy" component="div" className="invalid-feedback" />
                        <span>
                          <FormattedMessage id="global.preorder.rulez_2" />
                          <a
                            href={
                              translation === 'pl'
                                ? require('../../../Assets/file/regulations_corgi.pdf')
                                : require('../../../Assets/file/terms_of_service.pdf')
                            }
                            target="_blank"
                            rel="noopener noreferrer">
                            <FormattedMessage id="global.preorder.terms" />
                          </a>
                          <FormattedMessage id="global.preorder.rulez_and" />
                          <a
                            href={
                              translation === 'pl'
                                ? require('../../../Assets/file/privacy_policy.pdf')
                                : require('../../../Assets/file/privacy_policy en.pdf')
                            }
                            target="_blank"
                            rel="noopener noreferrer">
                            <FormattedMessage id="global.preorder.privacy_policy" />
                          </a>
                        </span>
                        <p className="checkmark"></p>
                      </label>
                    </div>
                    <div className={`expand-text expand-text-login ${expand ? 'expand-text-active' : ''}`}>
                      <span className="second_step-form-rulez-text checker-register--rodo">
                        <FormattedMessage id="global.preorder.rulez_text_2" />
                      </span>
                      <div className="expand-text--button expand-text--button-gray">
                        <div
                          className="expand-text--button-txt"
                          onClick={() => {
                            toggleExpand();
                          }}>
                          {expand ? (
                            <>
                              <FormattedMessage id="global.collapse" /> <SVG className="svg--chevron-black-up" type={SVG_TYPE.CHEVRON_DOWN} />
                            </>
                          ) : (
                            <>
                              <FormattedMessage id="global.expand" /> <SVG className="svg--chevron-black" type={SVG_TYPE.CHEVRON_DOWN} />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="packets-buttons">
                      <div className="packets-button-submit">
                        <Button
                          onClick={handleSubmit}
                          label={<FormattedMessage id="global.create_account" />}
                          classNames="btn--blue preorder-button"
                          iconImg={ChevronWhite}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RegisterModal;
