import React, { useRef, useEffect, lazy, Suspense } from 'react';
import { MainType } from './Container';
import DashboardSvg from 'Assets/Image/dashboard.webp';
import DashboardEnSvg from 'Assets/Image/dashboard_en.webp';
import CorgiShield from 'Assets/Image/corgi_shield.webp';
import TomaszOrganekPng from 'Assets/Image/tomasz_organek.png';
import Button from 'Shared/components/button/Button';
// import Background from '../../Assets/Image/background_hp.svg';
import { FormattedMessage } from 'react-intl';
import { ROUTES, SVG_TYPE } from 'Shared/enums';
import { Loader, SVG } from 'Shared/components';
import MetaTags from 'react-meta-tags';
const InlineWidget = lazy(() => import('react-calendly').then((module) => ({ default: module.InlineWidget })));

import { HowItWorks, ReviewsSlider } from './components';
import FadeInOnScroll from 'Components/FadeInOnScroll/FadeInOnScroll';
import { isMobile } from 'Shared/utils';
import ADD_WEB_SITE_ROUTES from 'Screens/AddWebsite/AddWebsiteRoutes';
import SlideOnScroll from 'Components/SlideOnScroll';
import {
  singlePageBackup,
  singlePageBackupEN,
  singlePageBackups,
  singlePageBackupsEN,
  singlePageMonitoring,
  singlePageMonitoringEN,
} from 'Assets/Image/homepage';

const scrollToRef = (ref) => {
  window.scroll({
    top: ref.current.offsetTop,
  });
};

const HomePage = ({ intl: { formatMessage }, actions: { getMyWebsites }, translation, history }: MainType) => {
  const myRef = useRef(null);

  const isPL = translation === 'pl';
  const {
    location: { origin, href },
  } = window;

  useEffect(() => {
    history.location.packages && setTimeout(() => scrollToRef(myRef));
    href === `${origin}/#pakiety` && scrollToRef(myRef);
    href === `${origin}${ROUTES.HOME_PAGE}/#pakiety` && setTimeout(() => scrollToRef(myRef));
    getMyWebsites(true);
  }, []);

  return (
    <div className="home-page">
      <MetaTags>
        <title>{formatMessage({ id: 'global.metatag.home_page_title' })}</title>
        <meta name="description" content={formatMessage({ id: 'global.metatag.home_page_description' })} />
      </MetaTags>
      <section className="section__wrapper section--gray">
        <div className="section--background section--background--top250">
          {/* <img src={Background} alt="background image" /> */}
          <SVG className="section--background-shields" type={SVG_TYPE.HERO_SECTION_BG} />
          <SVG className="section--background--shield" type={SVG_TYPE.HERO_SECTION_SHIELD} />
        </div>
        <div className="section banner mainHeading__content heroSection homepage__section-content">
          <SlideOnScroll direction="left" className="section__r">
            <h1 className="section--header mainHeading">
              <p className="paragraph">
                {formatMessage({ id: 'global.homepage_hero1' })}{' '}
                <span className={`typewrite typewrite--${translation}`}>{formatMessage({ id: 'global.homepage_hero2' })}</span>{' '}
                {formatMessage({ id: 'global.homepage_hero3' })}
              </p>
            </h1>
            <p className="section--under-header">{formatMessage({ id: 'global.homepage_subtitle' })}</p>
            <Button
              classNames="btn--blue headingButton"
              label={<FormattedMessage id="global.try_for_free" />}
              onClick={() => history.push(ADD_WEB_SITE_ROUTES.WEBSITE)}
            />
          </SlideOnScroll>
          <SlideOnScroll direction="right" delay={isMobile ? 300 : 900} className="section__l">
            <img className="homepage__main-logo" src={CorgiShield} alt="Logo pies woof" />
          </SlideOnScroll>
        </div>
      </section>

      <section className="section__wrapper section__wrapper-margin section--hero">
        <div className="section__wrapper--gray"> </div>
        <div className="section__wrapper--blue"> </div>
        <SVG type={SVG_TYPE.PAWS} className="homepage__background-paws" />
        <div className="homepage__one-tool">
          <FadeInOnScroll className="section homepage__one-tool-image section--no-margin">
            <img src={isPL ? DashboardSvg : DashboardEnSvg} alt="dashboard of corgi" />
          </FadeInOnScroll>
          <FadeInOnScroll delay={isMobile ? 300 : 900} className="section section--center">
            <div>
              <h2 className="homepage__subtitle homepage__subtitle--white homepage__subtitle--white homepage__subtitle--one-tool">
                {formatMessage({ id: 'global.one_tool_every_page' })}
              </h2>
              <p className="homepage__description homepage__description--white homepage__description--one-tool">
                {formatMessage({ id: 'global.one_tool_subtitle1' })}
                <br />
                {formatMessage({ id: 'global.one_tool_subtitle2' })}
              </p>
              <ul className="homepage__list">
                <li className="homepage__list-item">
                  <SVG type={SVG_TYPE.MONITOR_ICON} />
                  <p>
                    {formatMessage({ id: 'global.corgi_features.monitoring1' })} <b>{formatMessage({ id: 'global.corgi_features.monitoring2' })}</b>
                  </p>
                </li>
                <li className="homepage__list-item">
                  <SVG type={SVG_TYPE.MAIL_ICON_2} />
                  <p>
                    {formatMessage({ id: 'global.corgi_features.send_mail1' })} <b>{formatMessage({ id: 'global.corgi_features.send_mail2' })}</b>{' '}
                    {formatMessage({ id: 'global.corgi_features.send_mail3' })}
                  </p>
                </li>
                <li className="homepage__list-item">
                  <SVG type={SVG_TYPE.UPDATE_ICON} />
                  <p>
                    {formatMessage({ id: 'global.corgi_features.update1' })} <b>{formatMessage({ id: 'global.corgi_features.update2' })}</b>
                  </p>
                </li>
                <li className="homepage__list-item">
                  <SVG type={SVG_TYPE.UPLOAD_ICON} />
                  <p>
                    {formatMessage({ id: 'global.corgi_features.backup1' })} <b>{formatMessage({ id: 'global.corgi_features.backup2' })}</b>{' '}
                    {formatMessage({ id: 'global.corgi_features.backup3' })}
                  </p>
                </li>
                <li className="homepage__list-item">
                  <SVG type={SVG_TYPE.SHIELD_ICON} />
                  <p>{formatMessage({ id: 'global.corgi_features.protect' })}</p>
                </li>
                <li className="homepage__list-item">
                  <SVG type={SVG_TYPE.FILE_ICON} />
                  <p>
                    {formatMessage({ id: 'global.corgi_features.generate_raport1' })}{' '}
                    <b>{formatMessage({ id: 'global.corgi_features.generate_raport2' })}</b>
                  </p>
                </li>
              </ul>
              <Button
                classNames="btn--white-border btn--48-height btn--266-width"
                label={<FormattedMessage id="global.learn_more" />}
                onClick={() => history.push(ROUTES.HOW_IT_WORKS)}
              />
            </div>
          </FadeInOnScroll>
        </div>
      </section>

      <section className="section__wrapper homepage__features-container">
        <div className="section section--row section--center homepage__feature-wrapper">
          <FadeInOnScroll>
            <img src={isPL ? singlePageBackups : singlePageBackupsEN} alt="backups" />
          </FadeInOnScroll>
          <FadeInOnScroll delay={isMobile ? 300 : 900} className="section section--column section--center homepage__features">
            <h2 className="homepage__subtitle  homepage__subtitle--mg-b-24">{formatMessage({ id: 'global.fully_automatic' })}</h2>
            <p className="homepage__description">{formatMessage({ id: 'global.fully_automatic_description' })}</p>
          </FadeInOnScroll>
        </div>
        <div className="section section--row section--center homepage__feature-wrapper">
          <FadeInOnScroll delay={isMobile ? 300 : 900} className="section section--column section--center homepage__features">
            <h2 className="homepage__subtitle  homepage__subtitle--mg-b-24">{formatMessage({ id: 'global.passive_money' })}</h2>
            <p className="homepage__description">{formatMessage({ id: 'global.passive_money_description' })}</p>
          </FadeInOnScroll>
          <FadeInOnScroll>
            <img src={isPL ? singlePageMonitoring : singlePageMonitoringEN} alt="monitoring" />
          </FadeInOnScroll>
        </div>
        <div className="section section--row section--center homepage__feature-wrapper homepage__feature-wrapper--backup">
          <FadeInOnScroll>
            <img src={isPL ? singlePageBackup : singlePageBackupEN} alt="backup" />
          </FadeInOnScroll>
          <FadeInOnScroll delay={isMobile ? 300 : 900} className="section section--column section--center homepage__features">
            <h2 className="homepage__subtitle  homepage__subtitle--mg-b-24">{formatMessage({ id: 'global.empoyee_time_save' })}</h2>
            <p className="homepage__description">{formatMessage({ id: 'global.empoyee_time_save_description' })}</p>
          </FadeInOnScroll>
        </div>
      </section>

      <section className="section__wrapper homepage__reviews-wrapper">
        <div className="section__wrapper--blue section--relative"></div>
        <FadeInOnScroll className="section homepage__reviews homepage__section-content">
          <h2 className="homepage__subtitle homepage__subtitle--white homepage__subtitle--partner homepage__subtitle--big">
            {formatMessage({ id: 'global.look_at_our_partners_success' })}
          </h2>
          <ReviewsSlider formatMessage={formatMessage} />
        </FadeInOnScroll>
      </section>

      <HowItWorks formatMessage={formatMessage} history={history} isPL={isPL} />

      <section className="section__wrapper section__wrapper-margin section--who-earns">
        <div className="section__wrapper--gray"> </div>
        <div className="section__wrapper--blue"> </div>
        <FadeInOnScroll className="section homepage__section-content">
          <h2 className="homepage__subtitle homepage__subtitle--white homepage__subtitle--white homepage__subtitle--one-tool">
            {formatMessage({ id: 'global.who_earn_most' })}
          </h2>
          <FadeInOnScroll delay={isMobile ? 300 : 600} className="homepage__earn-most">
            <div className="homepage__earn-most-left">
              <SVG type={SVG_TYPE.CORGI_WITHOUT_WOOF} className="svg--corgi-shield" />
              <SVG type={SVG_TYPE.MONEY} className="svg--money" />
            </div>
            <ul className="homepage__earn-most-right">
              <FadeInOnScroll delay={isMobile ? 300 : 600} className="homepage__earn-most-list-item">
                <SVG type={SVG_TYPE.DESKTOP_ICON} />
                <h3> {formatMessage({ id: 'global.who_earn_most_options.website_creators' })}</h3>
                <p>{formatMessage({ id: 'global.who_earn_most_options.website_creators_description' })}</p>
              </FadeInOnScroll>
              <FadeInOnScroll delay={isMobile ? 300 : 800} className="homepage__earn-most-list-item">
                <SVG type={SVG_TYPE.SEARCH_ICON} />
                <h3> {formatMessage({ id: 'global.who_earn_most_options.seo' })}</h3>
                <p>{formatMessage({ id: 'global.who_earn_most_options.seo_description' })}</p>
              </FadeInOnScroll>
              <FadeInOnScroll delay={isMobile ? 300 : 1000} className="homepage__earn-most-list-item">
                <SVG type={SVG_TYPE.SMILE_ICON} />
                <h3> {formatMessage({ id: 'global.who_earn_most_options.assistant' })}</h3>
                <p>{formatMessage({ id: 'global.who_earn_most_options.assistant_description' })}</p>
              </FadeInOnScroll>
              <FadeInOnScroll delay={isMobile ? 300 : 1200} className="homepage__earn-most-list-item">
                <SVG type={SVG_TYPE.ANNOUNCEMENT_ICON} />
                <h3> {formatMessage({ id: 'global.who_earn_most_options.agency' })}</h3>
                <p>{formatMessage({ id: 'global.who_earn_most_options.agency_description' })}</p>
              </FadeInOnScroll>
            </ul>
          </FadeInOnScroll>
        </FadeInOnScroll>
      </section>

      <section className="section__wrapper">
        <div className="section section--center homepage__calendry-wrapper">
          <FadeInOnScroll delay={isMobile ? 300 : 600} className="homepage__calendry">
            <Suspense fallback={<Loader />}>
              <InlineWidget url="https://calendly.com/tomaszorganek/corgi_demo" />
            </Suspense>
          </FadeInOnScroll>
          <FadeInOnScroll className="homepage__demo">
            <img src={TomaszOrganekPng} alt="Tomasz Organek" />
            <h2 className="homepage__subtitle homepage__subtitle--black homepage__subtitle--partner homepage__subtitle--big homepage__subtitle--mg-b-24">
              {formatMessage({ id: 'global.make_a_demo' })}
            </h2>
            <p className="homepage__demo-description">{formatMessage({ id: 'global.make_a_demo_description' })}</p>
          </FadeInOnScroll>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
