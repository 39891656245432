import React, { useEffect, useState } from 'react';
import { SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';

function SvgTestPage() {
  const [isBlack, setIsBlack] = useState(false);
  useEffect(() => {
    const mainWrapper: any = document.querySelector('.main-wrapper');
    const p: any = document.querySelectorAll('p');
    if (isBlack) {
      mainWrapper.style.backgroundColor = 'black';
      p.forEach((el) => {
        el.style.color = 'white';
      });
    } else {
      mainWrapper.style.backgroundColor = 'white';
      p.forEach((el) => {
        el.style.color = 'black';
      });
    }
  }, [isBlack]);
  return (
    <ol>
      <div style={{ position: 'fixed', right: 0, top: 24 }}>
        <button onClick={() => setIsBlack(!isBlack)}>Change color</button>
      </div>
      {Object.keys(SVG_TYPE).map((svgType) => (
        <li key={svgType}>
          <p>{svgType}</p>
          <SVG type={svgType} />
        </li>
      ))}
    </ol>
  );
}
export default SvgTestPage;
